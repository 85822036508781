import React from "react";
import { Link } from "gatsby";
import "./style.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProjectCard = ({ title, img, role, id }) => {
  return (
    <Link to={`/projects/${id}`}>
      <div className="project-card--container">
        <div className="project-card--content">
          <h4 className="project-card--title">{title}</h4>
          <p className="project-card--role">{role}</p>
        </div>
        <div className="project-card--image-container">
          <GatsbyImage
            alt={`Project ${title} cover image`}
            image={getImage(img)}
            className="project-card--image"
          />
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
