import React from "react";
/* 👇 New code 👇 */
import { Link } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
/* 👇 Import the withAuthenticationRequired HOC 👇 */
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { PageLayout } from "components/layout/PageLayout";
import { useStaticQuery, graphql } from "gatsby";
import ProjectsTitle from "../../components/modules/ProjectsTitle";
import ProjectCard from "../../components/block/ProjectCard";

const Account = () => {
  /* 👇 Access user from the useAuth0 hook 👇 */
  const { user } = useAuth0();

  const data = useStaticQuery(graphql`
    {
      allContentfulProjects(sort: { fields: updatedAt, order: DESC }) {
        edges {
          node {
            id
            title
            role
            coverImage {
              gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
              resize(height: 640, width: 1200) {
                src
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <PageLayout>
        <Link to="/" className="link">
          👈 Back to Home
        </Link>

        <ProjectsTitle user={user.email} />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3 gap-y-3 items-stretch mt-10 m-auto">
          {data.allContentfulProjects.edges.map((item, i) => (
            <ProjectCard
              key={i}
              role={item.node.role}
              id={item.node.id}
              title={item.node.title}
              img={item.node.coverImage.gatsbyImage}
            />
          ))}
        </div>
      </PageLayout>
    </>
  );
};

/* 👇 Wrap the component in the withAuthenticationRequired handler 👇 */
export default withAuthenticationRequired(Account);
