import React from "react";
import "./style.css";

const ProjectsTitle = ({ user }) => {
  return (
    <section className="projects-header">
      <div className="hero__container">
        <h1 className="projects-heading">Hi {user}!</h1>
        <div className="flex content-center items-start">
          <div className="projects-icon-transition text-xl">👇</div>
          <p className="projects-subheading">
            Please see some of my select works below
          </p>
        </div>
      </div>
    </section>
  );
};

export default ProjectsTitle;
